
function Footer(){
    return(
            <footer className="w-full h-[300px] footer">

                <div className="w-full h-full flex flex-col md:flex-row m-auto shadow-2xl">
                    <div className="w-full md:w-1/3 h-full flex flex-col col1 justify-center items-center">
                    <p className="font-bold">Tijuana B.C., México</p>
                        <p>Av. Paseo Centenario #10310-101</p>
                        <p>Edificio Cazzar, Zona Río</p>
                        <p>Teléfono: 664 636 2091</p>
                        <a target="_blank" href="https://grupoconcordia.com/">www.grupoconcordia.com</a>
                    </div>
                    <div className=" w-full md:w-2/3 h-full flex flex-col justify-center items-center bg-gradient-to-r from-[#52bbaf] to-[#cdcc32] col2">
                        <h1 className="font-bold md:text-xl">Síguenos en nuestras redes sociales</h1>
                        <div className="iconos-footer flex gap-4">
                            <a target="_blank" href="https://www.facebook.com/grupoconcordiamx/"><i className="fa-brands fa-facebook-square"></i></a>
                            <a target="_blank" href="https://www.instagram.com/grupoconcordia/"><i className="fa-brands fa-instagram"></i></a>
                            <a target="_blank" href="https://www.youtube.com/channel/UCPtc8WHOip-C7gfRXdd2dgw"><i className="fa-brands fa-youtube"></i></a>
                        </div>
                        
                    </div>
                </div>
               
            </footer>
    )
}

export default Footer;