import Vacantes from "./vacantes";
import Modal from "./Modal";
import React from 'react';
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export default function TodosApp({vacantes}) {
  const [showModal, setShowModal] = useState();
  const [vacante, setVacante] = useState();

  const handleClickLeft = () => {
    const slider = document.querySelector('.slider')

    slider.scrollLeft -= 1000;
    slider.style.scrollBehavior = "smooth";
  }

  const handleClickRight = () => {
    const slider = document.querySelector('.slider')

    slider.scrollLeft += 1000;
    slider.style.scrollBehavior = "smooth";
  } 

  const HandleClick = async (e) => {

    const id = e.target.dataset.vacanteid
    
    setShowModal(true)

    let inicio = await fetch(`/server/gc_jobs_function/getVacanteById/${id}`);
    let aux = await inicio.json();
    setVacante(aux?.data?.data[0]);
    // console.log("Prueba Click")
  }
  let navigate = useNavigate();

  // console.log("todos: ",todos)
  return (
    <div id="vacantes" className=" h-fit w-full flex flex-col section_active gap-8 py-20 overflow-x-hidden">

        <div className="h-fit w-full max-w-6xl m-auto flex justify-between items-center">

          <p className="text-2xl md:text-4xl mx-5 lg:m-0 font-bold text-white">Tenemos una vacante para ti:</p>

          <div className=" hidden md:flex h-fit w-fit gap-3 botones-vacantes">
            <button className=" slide-left h-12 w-12 text-white rounded-full flex justify-center items-center"  onClick={handleClickLeft}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="black" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
              </svg>
            </button>
            <button className=" slide-right h-12 w-12 text-white rounded-full flex justify-center items-center" onClick={handleClickRight} >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="black" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
              </svg>
            </button>
          </div>  

        </div>
        
        <section className="slider flex">
          {vacantes && console.log(vacantes)}
          {
          vacantes && vacantes?.data?.data.map((todo, index) => {
            if(todo !== null){
              console.log("Todo: ",index," - ", todo)
              return (
                <Vacantes
                  key={index}
                  title={todo?.Job_Opening_Name}
                  description={todo?.Job_Type}
                  area={todo?.City}
                  id={todo?.id}
                  HandleClick={HandleClick}
                />
              );
            }
          })
          
          }
        </section>
        <div className="h-fit w-full max-w-6xl mx-5 lg:m-auto">
          <a href="/#/vacantes" className="text-white link-vacantes underline">Mira la lista de vacantes aquí<i className="fa-solid fa-arrow-right-long"></i></a>
        </div>
      <Modal showModal={showModal} setShowModal={setShowModal} vacante={vacante}/>
    </div>
  );
}


    
  

