import { useNavigate } from "react-router-dom";
// import {useState} from 'react'

function Menu({menu}){

  let navigate = useNavigate();

  const navigateInto = (e) => {
    e.target.classList.add('active')
    let idContainer = e.target.innerText.toLowerCase()
    let container = document.getElementById(`${idContainer}`)
    container.scrollIntoView({behavior: "smooth"});
  }

    return(
      <>
      {
        menu ? (
        <header className="fixed top-0 w-full z-30 h-20 flex items-center justify-center bg-[#182B31]">
        <nav className="navigation w-screen md:w-full max-w-6xl mx-8 flex justify-between items-center">
            <img onClick={()=>navigate("/")} className=" h-10 w-20 cursor-pointer" src="https://clientes.residencialvillatoscana.com/paginawebimg/logo-concordia-movil.png" alt="logo concordia"></img>
            <button id="toggle-menu-button" className="z-50 md:hidden relative" onClick={toogleMenu}>
              <div id="open" className="h-7 flex flex-col items-end justify-between">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="white" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16m-7 6h7" />
              </svg>
              </div>
              <div  id="close" className="hidden h-7 flex flex-col items-end justify-between">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="white" strokeWidth="2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </div>
          </button>
            <div id="toggle-menu" className="hidden md:block bg-[#182B31] fixed top-0 left-0 h-80 w-screen flex flex-col 
            items-center justify-center md:text-lg md:relative md:text-white md:bg-transparent md:w-auto md:h-auto `active ${show && 'hidden'}`">
              <div className="flex flex-col items-center justify-center gap-y-3 md:flex-row md:gap-10 transition-all menu-enlaces">
                <a href="/#/" className=" active text-center text-white px-6 py-2 rounded-md cursor-pointer">Inicio</a>
                <a target="_blank" href="https://www.facebook.com/people/Reclutamiento-Grupo-Concordia/100063651666385/"><i className="fa-brands fa-facebook-square text-4xl text-white"></i></a>
              </div>
            </div>
        </nav>
    </header>
    ) : (
      <header className="fixed top-0 w-full z-30 h-20 flex items-center justify-center bg-[#182B31]">
            <nav className="navigation w-screen md:w-full max-w-6xl mx-8 flex justify-between items-center">
                <img onClick={()=>navigate("/")} className=" h-10 w-20 cursor-pointer" src="https://clientes.residencialvillatoscana.com/paginawebimg/logo-concordia-movil.png"></img>
                <button id="toggle-menu-button" className="z-50 md:hidden relative" onClick={toogleMenu}>
                  <div id="open" className="h-7 flex flex-col items-end justify-between">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="white" strokeWidth="2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16m-7 6h7" />
                  </svg>
                  </div>
                  <div  id="close" className="hidden h-7 flex flex-col items-end justify-between">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="white" strokeWidth="2">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </div>
              </button>
                <div id="toggle-menu" className="hidden md:block bg-[#182B31] fixed top-0 left-0 h-80 w-screen flex flex-col 
                items-center justify-center md:text-lg md:relative md:text-white md:bg-transparent md:w-auto md:h-auto `active ${show && 'hidden'}`">
                  <div className="flex flex-col items-center justify-center gap-y-3 md:flex-row md:gap-10 transition-all menu-enlaces">
                    <p className="p-inicio active text-center text-white px-6 py-2 rounded-md cursor-pointer" onClick={navigateInto}>Inicio</p>
                    <p className="p-nosotros text-center text-white px-6 py-2 rounded-md cursor-pointer" onClick={navigateInto}>Nosotros</p>
                    <p className="p-vacantes text-center text-white px-6 py-2 rounded-md cursor-pointer" onClick={navigateInto}>Vacantes</p>
                    <p className="p-testimonios text-center text-white px-6 py-2 rounded-md cursor-pointer" onClick={navigateInto}>Testimonios</p>
                    <a target="_blank" href="https://www.facebook.com/people/Reclutamiento-Grupo-Concordia/100063651666385/"><i className="fa-brands fa-facebook-square text-4xl text-white"></i></a>
                  </div>
                </div>
            </nav>
        </header>
    )} 
    </>
    )
}
 
function toogleMenu(){
  let menu = document.querySelector('#toggle-menu');
  let open = document.querySelector('#open');
  let close = document.querySelector('#close');
  menu.classList.toggle('hidden');
  open.classList.toggle('hidden');
  close.classList.toggle('hidden'); 
}
  
function functionScroll() {
    var section = document.querySelectorAll(".section_active"),
        sections = {}, i = 0;

  Array.prototype.forEach.call(section, function(e) {
    sections[e.id] = e.offsetTop -200;
  });

    for (i in sections) {
      if (sections[i] <= window.scrollY +90) {
        document.querySelector('.active').classList.remove('active');
        document.querySelector('.p-' + i).classList.add('active');
      }
    }
  }

  window.addEventListener('scroll', functionScroll);
  window.addEventListener('resize', functionScroll);
export default Menu;