// import React, {useState, useEffect} from "react";
import Formulario from './Form'
import Requisitos from './Requisitos'
import Ofrecemos from './ofrecemos'
import Actividades from './Actividades'

export default function Modal({ showModal, setShowModal, vacante }) {
  let descripcion = `${vacante?.Job_Description}`;
  // const [showModal, setShowModal] = useState(false);
  // const [showForm, setShowForm] = React.useState(false);

  // useEffect(()=>{

  // },[vacante])

  const handleClick = (e) => {
    if (e?.target?.matches("[data-closed='true']")) {
      setShowModal(false)
    } else {
    }
  }

  return (
    <>
      {showModal ? (
        <>
          <div
            className="justify-center h-screen items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none container-modal"
            data-closed="true"
            onClick={handleClick}
          >
            <div className=" modal relative w-[90%] md:w-auto mx-auto h-[80%] lg:h-[660px] overflow-y-auto lg:overflow-y-hidden">
              {/*content*/}
              <div className="border-0 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex justify-center flex-col items-center p-5 bg-cover bg-bottom  bg-[url('https://clientes.residencialvillatoscana.com/paginawebimg/GCJobs/BannersModal.png')] header-form">
                  <p className="font-bold titulo-form italic">FORMA PARTE DE</p>
                  <p className="font-bold titulo-form italic">
                    NUESTRO EQUIPO DEL
                  </p>
                  <p className="font-extrabold text-white  text-center text-base md:text-xl uppercase">
                    {vacante?.Job_Opening_Name}
                  </p>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-gray-500 relative top-[-85px]  text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    x
                  </button>
                </div>
                {/*body*/}
                <div className="relative grid lg:grid-cols-2 lg:h-[660px] ">
                  <div className="flex flex-col gap-3 bg-[#EAF1F0] pt-36 sd:pt-44 p-6 lg:h-[660px] lg:overflow-y-auto">
                    <div className="Requerimientos">
                      <h1 className="text-slate-700 text-lg font-bold">
                        - Requisitos:
                      </h1>
                      {vacante === undefined ? (
                        <></>
                      ) : (
                        // console.log(vacante?.Requerimientos)
                        // <></>

                        vacante?.Requerimientos.map((element, index) => {
                          return (
                            <Requisitos
                              key={index}
                              Requerimientos={element?.Requisitos}
                            />
                          )
                        })
                      )}
                    </div>
                    <div className="Ofrecemos">
                      <h1 className="text-slate-700 text-lg font-bold">
                        - Ofrecemos:
                      </h1>
                      {vacante === undefined ? (
                        <></>
                      ) : (
                        // console.log(vacante?.Requerimientos)
                        // <></>

                        vacante?.Oferta.map((element, index) => {
                          return (
                            <Ofrecemos
                              key={index}
                              Oferta={element?.Ofrecemos}
                            />
                          )
                        })
                      )}
                    </div>
                    <div className="Actividades">
                      <h1 className="text-slate-700 text-lg font-bold">
                        - Actividades:
                      </h1>
                      {vacante === undefined ? (
                        <></>
                      ) : (
                        //console.log(vacante?.Requerimientos)
                        // <></>

                        vacante?.Actividad.map((element, index) => {
                          return (
                            <Actividades
                              key={index}
                              Actividad={element?.Actividades}
                            />
                          )
                        })
                      )}
                    </div>
                    <div>
                      <h1 className="text-slate-700 text-lg font-bold">
                        Descripción:
                      </h1>
                      {vacante?.Job_Description === null ?(
                        <></>
                      ):(
                        <span className='text-slate-500 text-m' dangerouslySetInnerHTML={{ __html: descripcion, }} />
                      )
                      
                      }
                    </div>
                  </div>

                  <div className="m-6 pt-0 lg:pt-28">
                    <Formulario vacante={vacante} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  )
}
