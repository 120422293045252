import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import {Autoplay, Pagination, Navigation } from "swiper";

function Testimonios(tetimonios) {   
  return (
    <>
    <div id="testimonios" className="w-full h-fit bg-[#182B31] p-10 pt-24 section_active">
      <h1 className=" text-center font-bold text-3xl lg:text-5xl text-[#de9f27]"> Testimonios de tus próximos compañeros</h1>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
      {
        tetimonios?.tetimonios?.map((element, index) =>{
          return(
            <SwiperSlide key={index}>
              <div className=" w-full h-full flex flex-col lg:flex-row justify-center items-center p-10 lg:gap-10 bg-[#182B31]">
                <div className="borde-gradiente rounded-full w-60 h-60 md:w-80 md:h-80">
                  <div className="w-full h-full rounded-full bg-gray-200">
                    <img loading="lazy" src={element.Imagen} className=" rounded-full"></img>
                  </div>
                </div>
                <div className=" w-full md:w-[650px] mt-6 mb-3 h-96 bg-[#182B31] text-white flex flex-col justify-center items-center gap-10">
                  <p className="text-sm text-justify md:text-lg">
                    <span className="comillas">“</span>{element.Comentario}<span className="comillas">”</span>
                  </p>
                  <section>
                    <p className=" font-bold text-xl text-[#de9f27]">{element.CustomModule2_Name}</p>
                    <p className=" font-bold text-xl">{element.Area}</p>
                  </section>
                </div>
              </div>
            </SwiperSlide>
          );
        })
      }
      </Swiper>
    </div>
    </>
  );
}
export default Testimonios;