import { useNavigate } from "react-router-dom";

function Inicio({inicio}) {
    let navigate = useNavigate();
    let data = inicio?.data?.data[0]
    let fondo = `${data?.Fondo_Inicio}`
    let Sujeto = `${data?.Sujeto}`
    return (
        <>
        {!inicio ? (<div>Loading...</div>) :
        (
        <div id="inicio" 
        style={{backgroundImage: `url(${fondo})`}}
        className = "h-screen w-full bg-cover bg-center flex justify-center items-center section_active"
        >
            
            <div className=" w-full md:w-2/5 h-full absolute right-0 top-0 z-0 bg-gradient-to-r from-[#52bbaf] to-[#cdcc32] hidden lg:flex figura-gradiente">
            </div>

            <div className="w-full h-full max-w-6xl flex justify-between items-center z-10">

                <div className=" h-full w-full md:ml-5 xl:ml-0 lg:w-fit flex flex-col justify-center items-center lg:items-start gap-10">

                    <div className=" flex flex-col justify-center items-center lg:items-start">
                        <h1 className="text-[2rem] md:text-5xl xl:text-6xl text-white font-bold tracking-tight mb-0">BOLSA DE TRABAJO</h1>
                        <h2 className="text-3xl md:text-4xl xl:text-5xl text-[#F4961B] font-bold tracking-tight mb-0">Grupo Concordia</h2>
                    </div>

                    <p className="w-[310px] md:w-[420px] text-white md:font-bold text-center lg:text-left">Encuentra nuevas oportunidades de trabajo todos los días, ¡Te estamos buscando!</p>
                    
                    <button onClick={()=>navigate("/vacantes")} className=" w-fit h-fit font-bold text-white flex items-center justify-center gap-2 md:gap-4 px-8 py-4 md:px-7 md:py-4.5 rounded-md bg-[#F4961B] hover:opacity-90 button-wiggle">
                        Ver vacantes
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                        </svg>
                    </button>

                </div>

                <div className=" h-full items-end hidden lg:flex z-10">
                    <img src={Sujeto} alt=""  className="w-auto h-[85%]"/>
                </div>

            </div>

        </div>
        )
        }
        </>
    )
}

export default Inicio;