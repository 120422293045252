import React, { useState, useEffect } from 'react';
import TodosApp from "./TodosApp";
import Inicio from "./Inicio"
import Nosotros from "./Nosotros"
import Testimonios from './Testimoniales';
import Footer from './footer';
import Menu from './Menu';
import Contador from './contador';
import Loader from './Loader';

function Home (){
    const [inicio, setInicio] = useState();
    const [load, setLoad] = useState(true);
    const [menu, setMenu] = useState();

    const loadInicio = async () => {
      // setLoad(true)
      let inicio = await fetch("/server/gc_jobs_function/getPageInfo");
      let aux = await inicio.json();
      setInicio(aux);
      // setLoad(false);
    };
  
    const [vacantes, setVacantes] = useState();
    const loadVacantes = async () => {
      let vacantes = await fetch("/server/gc_jobs_function/getVacantes");
      let aux = await vacantes.json();
      setVacantes(aux);
     };
  
    const [tetimonios, settetimonios] = useState();
    const loadTestimonios = async () => {
      let testimonios = await fetch("/server/gc_jobs_function/getTestimonios");
      let aux = await testimonios.json();
      settetimonios(aux.data?.data);
    };
  
    useEffect(() => {
      loadVacantes();
      loadInicio();
      loadTestimonios();
      setMenu(false)
      setTimeout(() => setLoad(false), 4000)
    }, []);

    return(
          <>
          {load ? (<Loader/>) :(
          <div className='container-app'>
            <div id="container-alert" className="container-alert"></div>
            <Menu menu={menu}/>
            <Inicio inicio={inicio}/>
            <Nosotros inicio={inicio}/>
            <TodosApp vacantes={vacantes}/>
            <Contador  inicio={inicio}/>
            <Testimonios tetimonios={tetimonios}/>
            <Footer/>
          </div>
         )}
        </> 
    )
}

export default Home;

