import { Fragment, useState } from "react";

function Nosotros({inicio}){
    let data = inicio?.data?.data[0];
    let nosotros = `${data?.Descripcion}`;
    let video = `${data?.Video}`
    return(
        <Fragment>
        <div id="nosotros" className="h-fit w-full max-w-6xl mx-auto section_active pt-24 pb-16 lg:py-28">
            <div className="titulo flex mb-0 justify-center items-center md:mb-10">
                <p className="text-3xl font-bold text-center md:text-5xl">
                    <span className="title-nosotros">Únete a nuestro equipo</span> y ayúdanos a que más familias adquieran su patrimonio.
                </p>
            </div>
            <div className="flex flex-col justify-center auto-cols-min gap-10 p-10 xl:p-0 w-full mx-auto md:grid lg:grid-cols-2 md:auto-cols-min">
                <div className="text-justify w-full max-w-6xl md:w-[45rem] lg:w-full h-fit flex flex-col gap-5 justify-center items-center my-auto">
                    <p className=" lg:text-xl leading-6 lg:leading-9">
                        {nosotros}
                    </p>
            </div>
                <div className="video-nosotros md:w-4/5 md:mx-auto">
                    <iframe
                    loading="lazy"
                    src = {video}
                    frameBorder="0"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                    title="video"
                    className="w-full aspect-[16/9]"
                    />
                </div>
            </div>
        </div>
        
        
        </Fragment>
    )
}


export default Nosotros;