import { React } from "react";

export default function Vacantes({ title, description, area, id, HandleClick}) {

  return (
    <div data-vacanteid={id} className="card h-80 md:h-auto min-w-[50vw] md:min-w-[60vw] lg:min-w-[30vw] vacante px-2.5 md:px-5 flex flex-col md:flex-row justify-center md:justify-start items-center gap-3" onClick={HandleClick}>
      <div className="icon-vacante rounded-full bg-[#F7B65F] p-3.5">
        <i className="fa-solid fa-briefcase text-white"></i>
      </div>
      <div className="flex desc-vacante flex-col justify-start gap-2">
        <p className="card-title font-bold text-base">{title}</p>
        <p className="card-body"><i className="fa-solid fa-clock text-white text-sm mr-2"></i>{description}</p>
        <p className="card-footer "><i className="fa-solid fa-location-dot text-white text-sm mr-2"></i>{area}</p>
      </div>
    </div>
  );
}