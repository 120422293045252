import { Fragment, useState, useEffect } from "react";

function Contador ({inicio}){
    let data = inicio?.data?.data[0]
    let colaboradores = data?.Colaboradores
    let contColaboradores = colaboradores - 200
    const [cont, setContador] = useState(contColaboradores)

    useEffect(() => {
        let timeout = 30
        if(cont < colaboradores)  setTimeout(() => {
            setContador(cont + 1)
        }, timeout);
    }, [cont])

    return(
        <Fragment>
            <div className=" sm:text-5xl font-bold flex flex-col justify-center text-center items-center mt-10 text-4xl contador">
                <p>Hoy somos <span className="title-nosotros" id="count">+ {cont}</span> colaboradores</p>
                <p>¡Solo nos faltas <span className="title-nosotros">tú!</span></p>
            </div>
            <div className="logos-desarrollos flex justify-center md:justify-between mx-auto my-10 w-full m-5 p-2 max-w-6xl flex-wrap items-center gap-3 lg:gap-0">
                <img src= {data?.Logo1} loading="lazy" className="grayscale h-20 md:w-auto md:h-36"></img>
                <img src= {data?.Logo2} loading="lazy" className="grayscale h-20 md:w-auto md:h-36"></img>
                <img src= {data?.Logo3} loading="lazy" className="grayscale h-20 md:w-auto md:h-36"></img>
                <img src= {data?.Logo4} loading="lazy" className="grayscale h-20 md:w-auto md:h-36"></img>
                <img src= {data?.Logo5} loading="lazy" className="grayscale h-20 md:w-auto md:h-36"></img>
            </div>
        </Fragment>
    )
} 

export default Contador;
