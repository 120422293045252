import React, { useState, useEffect } from "react";
import Menu from "./Menu";
import Vacantes from "./vacantes";
import Footer from "./footer";
import Modal from "./Modal";
import Loader from "./Loader";

function PaginaVacantes() {
  const [vacantes, setVacantes] = useState();
  const [vacante, setVacante] = useState();
  const [showModal, setShowModal] = useState();
  const [load, setLoad] = useState();
  const [menu, setMenu] = useState();

  const loadVacantes = async () => {
    setLoad(true);
    let vacantes = await fetch("/server/gc_jobs_function/getVacantes");
    let aux = await vacantes.json();
    setVacantes(aux);
    setLoad(false);
  };

  useEffect(() => {
    loadVacantes();
    setMenu(true);
  }, []);

  const HandleClick = async (e) => {
    e.preventDefault();

    // console.log("datasets: ", e.target.dataset);
    const id = e.target.dataset.vacanteid;

    // console.log("ID: ", id);

    setShowModal(true);

    let inicio = await fetch(`/server/gc_jobs_function/getVacanteById/${id}`);
    let aux = await inicio.json();
    setVacante(aux?.data?.data[0]);
  };

  return (
    <>
      {load ? (
        <Loader />
      ) : (
        <>
          <div id="container-alert" className="container-alert"></div>
          <div>
            <div className="menu-vacantes">
              <Menu menu={setMenu} />
            </div>
            <div className="w-full flex justify-center items-center pt-24">
              <section className="m-5 grid grid-cols-1 lg:grid-cols-2 max-w-5xl gap-2 page-vacante">
                {vacantes?.data?.data.map((todo, index) => {
                  // console.log("vacante: ", index, todo );
                  // console.log("Todo: ",index," - ", todo)
                  if(todo !== null){
                    console.log("Todo: ",index," - ", todo)
                    return (
                      
                      <Vacantes
                        key={index}
                        title={todo?.Job_Opening_Name}
                        description={todo?.Job_Type}
                        area={todo?.City}
                        id={todo?.id}
                        HandleClick={HandleClick}
                      />
                    );
                  }
                })}
              </section>
            </div>
          </div>
          <Modal
            showModal={showModal}
            setShowModal={setShowModal}
            vacante={vacante}
          />
          <Footer />
        </>
      )}
    </>
  );
}

export default PaginaVacantes;
