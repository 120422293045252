import React, { useState, useEffect } from 'react'
import Alertas from '../Alertas'
// import { request } from '../../../functions/gc_jobs_function'

const Formulario = ({ vacante }) => {
  //   console.log(vacante)

  const vacanteId = vacante !== undefined ? vacante?.id : undefined
  const [nombre, setNombre] = useState('')
  const [apellido, setApellido] = useState('')
  const [correo, setCorreo] = useState('')
  const [telefono, setTelefono] = useState('')
  const [interesado, setInteresado] = useState('')
  const [actual, setActual] = useState('')
  const [experiencia, setExperiencia] = useState('')
  const [escolaridad, setEscolaridad] = useState('')
  const [calle, setCalle] = useState('')
  const [ciudad, setCiudad] = useState('')
  const [estado, setEstado] = useState('')
  const [pais, setPais] = useState('')

  const [file, setFile] = useState('')
  const [viewSubmit, setViewSubmit] = useState(false)

  const handleChange = (e) => {
    if (e.target.files[0].type === 'application/pdf') {
      setFile(e.target.files[0])
      e.target.labels[0].childNodes[1].innerHTML = `${e.target.files[0].name}`
      setViewSubmit(true)
    } else {
      setViewSubmit(false)
    }

    // console.log('file', file)
    // console.log(e.target.files[0])
  }

  useEffect(() => {
    const vacanteInteresado = vacante !== undefined ? vacante?.Job_Opening_Name : undefined
    setInteresado(vacanteInteresado)
  },[vacante])

  const handleSubmit = async (e) => {
    e.preventDefault()

    let checkValid = validate(e)
    if(checkValid){
      const jsonCandidate = {
        First_Name: nombre,
        Last_Name: apellido,
        Email: correo,
        Mobile: telefono,
        Interesado: interesado,
        Current_Job_Title: actual,
        Experience_in_Years: experiencia,
        Escolaridad: escolaridad,
        City: ciudad,
        Estado: estado,
        Country: pais,
        Street: calle
      }
  
      try {
        const request = await fetch(`/server/gc_jobs_function/createCandidate`, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(jsonCandidate),
        })
  
        const response = await request.json()
  
        if (response.ok) {

          Alertas.showAlert(response.type,'Candidato creado.')

          const candidateId = response?.data.details.id
          // Associate Candidate
          // console.log(candidateId, vacanteId)
          const associateRequest = await fetch(
            `/server/gc_jobs_function/associateCandidate`,
            {
              method: 'put',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                jobids: [vacanteId.toString()],
                ids: [candidateId.toString()],
                comments: 'Vacante postulado desde GC Jobs',
              }),
            }
          )
  
          const associateResponse = await associateRequest.json()

          if(associateResponse.ok){
            Alertas.showAlert(associateResponse.type,'Candidato Asociado.')
          }else{
            Alertas.showAlert(associateResponse.type,associateResponse.message)
          }
          

          // console.log('associate', associateResponse)
          let formDataRequestBody = new FormData()
          formDataRequestBody.append('file', new Blob([file]), file.name)
          let reqBody = formDataRequestBody
  
          // console.log('reqBody', reqBody)
  
          const fileRequest = await fetch(
            `/server/gc_jobs_function/uploadRecruit/${candidateId}`,
            {
              method: 'post',
              body: reqBody,
            }
          )
  
          const fileResponse = await fileRequest.json()

          

          if(fileResponse.ok){
            Alertas.showAlert('Success','Archivo enviado.')
          }else{
            Alertas.showAlert(fileResponse.type,fileResponse.message)
          }

          // console.log(fileResponse)
        }else{
          Alertas.showAlert(response.type,response.message)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
  const remove = (e) => {
    e.target.classList.remove('invalid')
  }
  const validate = (e) => {
    let cont = 0
    for (let i = 0; i < e.target.length; i++){
        if(e.target[i].dataset.required === 'true')
        {
          if(e.target[i].value === "")
          {
            e.target[i].classList.remove('invalid');
            window.requestAnimationFrame(function() {
              e.target[i].classList.add('invalid');
            })
          }else{
            cont = cont + 1;
          }
        }
    }
    
    if(cont === 4){
      // Alertas.showAlert('Enviado','Candidato enviado.')
      return true
    }else{
      Alertas.showAlert('Warning','DATOS INVALIDOS')
      return false
    }
  }

  return (
    <form
      id="form-candidates"
      className="grid grid-cols-2 gap-2"
      onSubmit={handleSubmit}
    >
      <input
        className=" border-input col-span-2"
        type="text"
        placeholder="Nombre(s):"
        data-required = 'true'
        onChange={(e) => {setNombre(e.target.value); remove(e);}}
      ></input>
       <input
        className=" border-input col-span-2"
        type="text"
        data-required = 'true'
        placeholder="Apellidos:"
        onChange={(e) => {setApellido(e.target.value); remove(e);}}
      ></input>
      <input
        className=" border-input"
        type="email"
        placeholder="Correo:"
        data-required = 'true'
        onChange={(e) => {setCorreo(e.target.value); remove(e);}}
      ></input>
      <input
        className=" border-input"
        type="tel"
        placeholder="Telefono:"
        minLength={10}
        maxLength={12}
        data-required = 'true'
        onChange={(e) => {setTelefono(e.target.value); remove(e);}}
      ></input>
      <input
        className=" border-input"
        type="text"
        value={interesado}
        placeholder="Puesto Interesado:"
        // onChange={(e) => setInteresado(e.target.value)}
        disabled
      ></input>
      <input
        className=" border-input"
        type="text"
        placeholder="Puesto Actual:"
        onChange={(e) => setActual(e.target.value)}
      ></input>
      <input
        className=" border-input"
        type="number"
        placeholder="Experiencia (años):"
        onChange={(e) => setExperiencia(e.target.value)}
      ></input>
      <input
        className=" border-input"
        type="text"
        placeholder="Escolaridad:"
        onChange={(e) => setEscolaridad(e.target.value)}
      ></input>
      <input
        className="border-input"
        type="text"
        placeholder="Calle:"
        onChange={(e) => setCalle(e.target.value)}
      ></input>
      <input
        className="border-input"
        type="text"
        placeholder="Ciudad:"
        onChange={(e) => setCiudad(e.target.value)}
      ></input>
      <input
        className=" border-input"
        type="text"
        placeholder="Estado:"
        onChange={(e) => setEstado(e.target.value)}
      ></input>
      <input
        className="border-input"
        type="text"
        placeholder="Pais:"
        onChange={(e) => setPais(e.target.value)}
      ></input>
      <label className=" label-file flex flex-col items-center justify-center py-6 rounded-lg cursor-pointer col-span-2">
        <i className="fa-solid fa-file-arrow-up"></i>
        <span className="mt-2 text-slate-500">Adjunta tu CV</span>
        <input
          type="file"
          id="file-cv"
          className="hidden"
          onChange={handleChange}
        />
      </label>
      {viewSubmit === false ? (
        <></>
      ) : (
        <button
          type="submit"
          className="col-span-2 btn-form w-full py-3 rounded-md font-bold text-white"
        >
          POSTULAR
        </button>
      )}
    </form>
  )
}
export default Formulario