import {HashRouter as Router,Routes,Route} from "react-router-dom";
import PaginaVacantes from './components/PaginaVacantes';
import Home from './components/Home';

function App() {
  return (
    <div className='container-app'>
      <Router>
        <Routes>
          <Route path="/vacantes" element={<PaginaVacantes/>}></Route>
          <Route path="/" element={<Home/>}></Route>
          <Route path="*" element={<Home/>}></Route>
        </Routes>
      </Router>
    </div>
  )
}

export default App
